import { Injectable } from '@angular/core';
import { CommandsBase } from 'src/app/commands/commands-base';

@Injectable({
  providedIn: 'root',
})
export class DevsCommands extends CommandsBase {
  constructor() {
    super();
  }

  get() {
    return [
      {
        name: 'Kroete',
        alias: 'kroete',
        description: 'Opens Kroete`s github',
        command: () => {
          window.location.href = 'https://github.com/Kroete1979';
          return 'bye';
        },
      },
      {
        name: 'Donkoleon',
        alias: 'donkoleon',
        description: 'Opens Donkoleon`s github',
        command: () => {
          window.location.href = 'https://github.com/LeonHaemmerling';
          return 'bye';
        },
      },
      {
        name: 'edraft',
        description: 'Opens edraft`s website',
        command: () => {
          window.location.href = 'https://www.sh-edraft.de/';
          return 'bye';
        },
      },
    ];
  }
}
