import { Injectable } from '@angular/core';
import { CommandsBase } from 'src/app/commands/commands-base';
import { Logger, LogLevel } from 'src/app/services/logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class LoggingCommands extends CommandsBase {
  constructor() {
    super();
  }

  get() {
    return [
      {
        name: 'log level',
        description: 'Prompts active log level',
        command: () => {
          return LogLevel[Logger.level].toString();
        },
      },
      ...Object.keys(LogLevel)
        .filter(x => isNaN(Number(x)))
        .map(x => {
          return {
            name: `log ${x.toLowerCase()}`,
            description: `Sets log level to ${x.toLowerCase()}`,
            command: () => {
              const oldLevel = LogLevel[Logger.level].toString();
              Logger.level = LogLevel[x as keyof typeof LogLevel];
              return `Set log level ${oldLevel} -> ${LogLevel[Logger.level].toString()}`;
            },
          };
        }),
    ];
  }
}
