import { Injectable } from '@angular/core';
import { CommandsBase } from 'src/app/commands/commands-base';
import { PersonalSettingsService } from 'src/app/modules/shared/services/personal-settings.service';

@Injectable({
  providedIn: 'root',
})
export class NfcCommands extends CommandsBase {
  constructor(private personalSettings: PersonalSettingsService) {
    super();
  }

  get() {
    return [
      {
        name: 'nfc disable',
        description: 'disable nfc listener',
        command: () => {
          this.personalSettings.setSetting('disableNfcListener', true);
          return 'Disabled, reload page to apply changes';
        },
      },
      {
        name: 'nfc enable',
        description: 'enable nfc listener',
        command: () => {
          this.personalSettings.setSetting('disableNfcListener', false);
          return 'Enabled, reload page to apply changes';
        },
      },
    ];
  }
}
