import { Injectable } from '@angular/core';
import { CommandsBase } from 'src/app/commands/commands-base';

@Injectable({
  providedIn: 'root',
})
export class GeneralCommands extends CommandsBase {
  constructor() {
    super();
  }

  get() {
    return [
      {
        name: 'ping',
        description: 'Pong!',
        command: () => 'pong',
      },
      {
        name: 'more',
        description: 'Find out more about our projects',
        command: () => {
          window.location.href = 'https://www.area49-shop.de/entwicklung';
          return 'bye';
        },
      },
    ];
  }
}
