import { Injectable } from '@angular/core';
import { CommandsBase } from 'src/app/commands/commands-base';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class SpinnerCommands extends CommandsBase {
  constructor(private spinner: SpinnerService) {
    super();
  }

  get() {
    return [
      {
        name: 'spinner',
        description: 'toggle spinner',
        command: () => {
          this.spinner.toggle();
          return 'Toggled';
        },
      },
    ];
  }
}
